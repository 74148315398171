import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Keycloak from "keycloak-js";
import {ReactKeycloakProvider} from '@react-keycloak/web';
import {AuthenticatedProxy} from "./AuthenticatedProxy";
import {computeUrl} from "./util";

const keycloakConfiguration = Keycloak({
    url: `${(computeUrl('login.', 'localhost:9095'))}/auth/`,
    realm: "global-sales-demo",
    clientId: "landing-page"
});

const keycloakInitOptions = {
    onLoad: 'login-required'
}

ReactDOM.render(
    <React.StrictMode>
        <ReactKeycloakProvider authClient={keycloakConfiguration} initOptions={keycloakInitOptions}>
            <AuthenticatedProxy>
                <App/>
            </AuthenticatedProxy>
        </ReactKeycloakProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
