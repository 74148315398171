export function computeUrl(prefix: string, fallbackUrl: string) {
    const hostname = window.location.host;

    let url = fallbackUrl;
    if (hostname.indexOf("localhost") === -1) {
        const firstDot = hostname.indexOf(".") + 1;
        url = `${prefix}${hostname.substring(firstDot)}`;
    }

    return `${window.location.protocol}//${url}`;
}

export const flowableUrl = `${computeUrl('', 'localhost:3000')}`;
export const managerApiUrl = `${computeUrl('home.', 'localhost:3000')}`;
export const socketUrl = `${computeUrl('home.', 'localhost:8091')}`;
