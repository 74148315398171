import {useEffect, useState} from "react";
import {useKeycloak} from "@react-keycloak/web";
import {fetchAuthenticated} from "./request";
import {flowableUrl} from "./util";

export function useWorkItems() {
    return useFlowableApi(`${flowableUrl}/platform-api/work-definitions`);
}

export function useStartForm(workDefinitionType: 'case' | 'process', workDefinitionKey: string) {
    let url = workDefinitionType && workDefinitionKey ? `${flowableUrl}/platform-api/${workDefinitionType}-definitions/${workDefinitionKey}/start-form` : null;
    let result = useFlowableApi(url, [workDefinitionKey, workDefinitionType]);
    if (result && !result.outcomes) {
        result.outcomes = [
            {
                value: '__SUBMIT',
                label: 'Submit'
            }
        ]
    }
    return result;
}

export function useFormByKey(formKey: string) {
    let url = formKey ? `${flowableUrl}/form-api/form-repository/form-definitions/key/${formKey}/model` : null;
    return useFlowableApi(url, [formKey]);
}

export function useFlowableTheme() {
    return useFlowableApi(`${flowableUrl}/platform-api/configuration/themes-default`);
}

export function useCurrentUser() {
    return useFlowableApi(`${flowableUrl}/idm-api/current-user`);
}

function useFlowableApi(url: string, dependencies: string[] = []) {
    const [result, setResult] = useState(null);
    const {keycloak} = useKeycloak();

    useEffect(() => {
        if (!result && url) {
            fetchAuthenticated(keycloak, url)
                .then(response => response.ok ? response.json() : Promise.reject(response))
                .then(data => setResult(data));
        }
        // eslint-disable-next-line
    }, [...dependencies]);
    return result;
}


