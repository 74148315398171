import * as React from "react";
import {useState} from "react";
import {useKeycloak} from "@react-keycloak/web";
import {fetchAuthenticated} from "./request";
import {managerApiUrl} from "./util";
import {Button, TextField} from "@mui/material";

export function SendMessage({showSnackbarWithConfiguration, setShowLoading, showLoading}) {

    const {keycloak} = useKeycloak();
    const [tenantId] = useState((keycloak.tokenParsed as any)?.tenantId);

    const [customerId, setCustomerId] = useState("");
    const [cardId, setCardId] = useState("");
    const [gateId, setGateId] = useState('Gate A');

    const sendMessage = () => {
        setShowLoading(true);
        let message = {
            customerId,
            cardId,
            gateId,
            tenantId
        };
        const body = new Blob([JSON.stringify(message)], {type: 'application/json'});
        fetchAuthenticated(keycloak, `${managerApiUrl}/event-api/message/fitness-card-topic`, {
            method: 'POST',
            body
        })
            .then(response => {
                setShowLoading(false);
                if (response.ok) {
                    showSnackbarWithConfiguration({
                        severity: 'success',
                        message: `Message sent: ${JSON.stringify(message, null, 1)}`
                    });
                    return Promise.resolve();
                } else {
                    showSnackbarWithConfiguration({
                        severity: 'error',
                        message: `Failed to send message`
                    });
                    return Promise.reject(response);
                }
            });
    }

    return <div>
        <div style={{marginBottom: "5px"}}>
            <TextField label="Customer ID" type="text" value={customerId} onChange={c => setCustomerId(c.target.value)} style={{marginRight: "4px"}}/>
            <TextField label="Card ID" type="text" value={cardId} onChange={c => setCardId(c.target.value)} style={{marginRight: "4px"}}/>
            <TextField label="Gate ID" type="text" value={gateId} onChange={c => setGateId(c.target.value)}/>
        </div>
        <Button variant="contained" onClick={() => {
            sendMessage()
        }}>Scan card
        </Button>
    </div>;
}