import * as React from "react";
import {useState} from "react";
import {useKeycloak} from "@react-keycloak/web";
import {fetchAuthenticated} from "./request";
import {managerApiUrl} from "./util";
import {Button} from "@mui/material";
import {Form} from "@flowable/forms";
import {useFormByKey} from "./hooks";
import {useParams} from "react-router-dom";
import {Card, CardContent} from "@material-ui/core";

export function SendMessageForm({showSnackbarWithConfiguration, setShowLoading, showLoading}) {

    const {formKey, topic} = useParams() as { formKey: string, topic: string };

    const {keycloak} = useKeycloak();
    const [tenantId] = useState((keycloak.tokenParsed as any)?.tenantId);

    const formDefinition = useFormByKey(formKey);

    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState({});

    const sendMessage = () => {
        setShowLoading(true);
        let messageWithTenantId = {...message, tenantId};
        const body = new Blob([JSON.stringify(messageWithTenantId)], {type: 'application/json'});
        fetchAuthenticated(keycloak, `${managerApiUrl}/event-api/message/${topic}`, {
            method: 'POST',
            body
        })
            .then(response => {
                setShowLoading(false);
                if (response.ok) {
                    const body = JSON.stringify(messageWithTenantId, null, 1);
                    showSnackbarWithConfiguration({
                        severity: 'success',
                        message: `Message sent: ${body}`
                    });
                    setMessages(m => [body, ...m]);
                    setMessage({});
                    return Promise.resolve();
                } else {
                    showSnackbarWithConfiguration({
                        severity: 'error',
                        message: `Failed to send message`
                    });
                    return Promise.reject(response);
                }
            });
    }

    return <>
        <div style={{float: "left", width: "49%"}}>
            <div>
                <div style={{marginBottom: "5px"}}>
                    {formDefinition && <Form config={formDefinition} payload={message} onChange={e => setMessage(e)}/>}
                </div>
                <div style={{margin: "12px"}}>
                    <Button variant="contained" onClick={() => sendMessage()}>Send</Button>
                </div>
            </div>
        </div>
        <div style={{float: "right", width: "49%"}}>
            {messages.map((message, i) => {
                return <Card key={`message-${i}`} style={{marginBottom: '5px'}}>
                    <CardContent>
                        <pre>{message}</pre>
                    </CardContent>
                </Card>;
            })}
        </div>
    </>;
}
