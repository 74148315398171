import keycloak from "keycloak-js";

export function fetchAuthenticated(keycloak: keycloak.KeycloakInstance, input: RequestInfo, init?: RequestInit) {
    return keycloak.updateToken(300)
        .then(() => {
            if (!init) {
                init = {};
            }
            if (!init.headers) {
                init.headers = {};
            }
            if (init.headers instanceof Headers) {
                init.headers.set("Authorization", `Bearer ${keycloak.token}`);
            } else {
                (init.headers as any).Authorization = `Bearer ${keycloak.token}`;
            }
            return fetch(input, init);
        })
        .catch((reason) => {
            console.error('Failed to update keycloak token', input, reason);
            return Promise.reject(reason);
        });
}
