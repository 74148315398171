import * as React from 'react';
import {useWorkItems} from "./hooks";
import {Button, Card, CardActions, CardContent, makeStyles, Typography} from "@material-ui/core";

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    root: {
        width: 275,
        margin: 5
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});
export function Work() {

    const classes = useStyles();

    const workItems = useWorkItems();

    return workItems ? <div className={classes.container}>
        {workItems.map((workItem, i) =>
            <Card className={classes.root} key={`work-item-${i}`}>
                <CardContent>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        {workItem.appName}
                    </Typography>
                    <Typography variant="h5" component="h2">
                        {workItem.name}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size="small" href={`/work/${workItem.key}`}>Open</Button>
                </CardActions>
            </Card>
        )}
    </div> : <>You don't have access to any applications</>;
}
