import * as React from 'react';
import {useEffect, useState} from 'react';
import * as SockJS from 'sockjs-client';
import * as Stomp from 'stomp-websocket';
import {Card, CardContent, CardHeader, Typography, TextField} from "@material-ui/core";
import {SendMessage} from "./SendMessage";
import {socketUrl} from "./util";

export function Messages(props) {

    const [messages, setMessages] = useState([]);
    const [doorImage, setDoorImage] = useState('door-close.gif');

    const updateDoorImage = (message) => {
        if (message.topic == 'gate-action-topic') {
            if (message.message.status == 'Open') {
                setDoorImage('door-open.gif?time=' + new Date().getTime());
            } else {
                setDoorImage('door-close.gif?time=' + new Date().getTime());
            }
        }
    };

    useEffect(() => {
        const socket = new SockJS(`${socketUrl}/websocket`);
        const stompClient = Stomp.over(socket);
        let connection = null;
        stompClient.connect({
            Authorization: ''
        }, () => {
            connection = stompClient.subscribe('/topic/pushNotification', (notification) => {
                let body = {...JSON.parse(notification.body), date: new Date()};
                updateDoorImage(body);
                setMessages(m => [body, ...m]);
            });
        });

        return () => {
            if (connection != null) {
                connection.unsubscribe();
                connection = null;
            }
            stompClient.disconnect();
        }
    }, []);

    return <>

        <div style={{float: "left", width: "49%"}}>
            {messages.map((message, i) => {
                return <Card key={`message-${i}`} style={{marginBottom: '5px'}}>
                    <CardHeader>topic: </CardHeader>
                    <CardContent>
                        <Typography variant="h5" component="h2">
                            {message.topic}
                        </Typography>
                        <Typography color="textSecondary" gutterBottom>
                            {message.date.toString()}
                        </Typography>
                        <pre>{JSON.stringify(message.message, null, 3)}</pre>
                    </CardContent>
                </Card>;
            })}
        </div>
        <div style={{float: "right", width: "49%"}}>
            <SendMessage {...props}></SendMessage>

            <img src={`/${doorImage}`} />
        </div>
        <br style={{clear: "both"}} />
    </>;
}