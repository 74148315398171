import * as React from "react";
import {useState} from "react";
import {useKeycloak} from "@react-keycloak/web";
import {fetchAuthenticated} from "./request";
import {managerApiUrl} from "./util";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {Button, TextField} from "@mui/material";
import {Card, CardContent, CardHeader, Typography} from "@material-ui/core";

export function SendMessage({setMessage, showSnackbarWithConfiguration, setShowLoading, showLoading}) {

    const [messages, setMessages] = useState([]);

    const {keycloak} = useKeycloak();
    const [tenantId] = useState((keycloak.tokenParsed as any)?.tenantId);
    const [customerId, setCustomerId] = useState("");
    const [eventType, setEventType] = useState("");
    const [subEventType, setSubEventType] = useState("");

    const initialEventOptions = [
        { title: "addressChange" },
        { title: "shoeSizeChange" },
        { title: "suspiciousTransaction" }
    ];

    const initialSubEventOptions = [
        { title: "withinCountry" },
        { title: "withinEU" },
        { title: "withinEFTA" },
        { title: "withinEEA" },
        { title: "nonSanctioned" },
        { title: "sanctioned" },
        { title: "smaller" },
        { title: "bigger" }
    ];

    const [eventOptions, setEventOptions] = React.useState(initialEventOptions);
    const [subEventOptions, setSubEventOptions] = React.useState(initialSubEventOptions);


    const sendMessage = () => {
        setShowLoading(true);
        let message = {
            customerId,
            eventType,
            subEventType,
            tenantId
        };
        const body = new Blob([JSON.stringify(message)], {type: 'application/json'});
        fetchAuthenticated(keycloak, `${managerApiUrl}/event-api/message/aml-demo-topic`, {
            method: 'POST',
            body
        })
            .then(response => {
                setShowLoading(false);
                var body = JSON.stringify(message, null, 1)
                if (response.ok) {
                    showSnackbarWithConfiguration({
                        severity: 'success',
                        message: `Message sent: ${body}`
                    });
                    setMessages(m => [body, ...m]);
                    return Promise.resolve();
                } else {
                    showSnackbarWithConfiguration({
                        severity: 'error',
                        message: `Failed to send message`
                    });
                    return Promise.reject(response);
                }
            });
    }

    return  <>
        <div style={{float: "left", width: "49%"}}>
        {messages.map((message, i) => {
            return <Card key={`message-${i}`} style={{marginBottom: '5px'}}>
                <CardHeader>topic: </CardHeader>
                <CardContent>
                    <pre>{message}</pre>
                </CardContent>
            </Card>;
        })}
        </div>
        <div style={{float: "right", width: "49%"}}>
            <div>
                <div style={{marginBottom: "5px"}}>
                    <TextField label="Customer ID" type="text" value={customerId} onChange={c => setCustomerId(c.target.value)} style={{marginRight: "4px"}}/>
                    <br/><br/>
                    <Autocomplete
                        options={eventOptions}
                        getOptionLabel={(option) => option.title}
                        onInputChange={(e, newValue) => {
                            setEventType(newValue);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Event Type"
                                variant="outlined"
                                style={{marginRight: "4px"}}
                                onKeyDown={(e) => {
                                    if (
                                        e.key === "Enter" &&
                                        eventOptions.findIndex((o) => o.title === eventType) === -1
                                    ) {
                                        setEventOptions((o) => o.concat({ title: eventType }));
                                    }
                                }}
                            />
                        )}
                    />
                    <br/>
                    <Autocomplete
                        options={subEventOptions}
                        getOptionLabel={(option) => option.title}
                        onInputChange={(e, newValue) => {
                            setSubEventType(newValue);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Sub Event Type"
                                variant="outlined"
                                style={{marginRight: "4px"}}
                                onKeyDown={(e) => {
                                    if (
                                        e.key === "Enter" &&
                                        subEventOptions.findIndex((o) => o.title === subEventType) === -1
                                    ) {
                                        setSubEventOptions((o) => o.concat({ title: subEventType }));
                                    }
                                }}
                            />
                        )}
                    />
                </div>
                <br/>
                <Button variant="contained" onClick={() => {
                    sendMessage()
                }}>Report Detection
                </Button>
            </div>
        </div>
        <br style={{clear: "both"}} />
    </>;



    ;
}