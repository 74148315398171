import * as React from 'react';
import {useState} from 'react';
import {useCurrentUser, useStartForm, useWorkItems} from "./hooks";
import {useParams} from 'react-router-dom';
import {Form} from "@flowable/forms";
import MuiAlert from '@material-ui/lab/Alert';
import {Snackbar} from "@material-ui/core";
import {fetchAuthenticated} from "./request";
import {useKeycloak} from "@react-keycloak/web";
import {flowableUrl} from "./util";

export function WorkForm({setShowLoading}) {

    const {workDefinitionKey} = useParams() as { workDefinitionKey: string };
    const {keycloak} = useKeycloak();
    const workItems = useWorkItems();
    const workItem = workItems && workItems.filter((workItem) => workItem.key === workDefinitionKey)[0];
    const startForm = useStartForm(workItem?.type, workItem?.id);
    const currentUser = useCurrentUser();
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(false);

    const handleCloseError = (event, reason?) => {
        if (reason === 'clickaway') {
            return;
        }

        setError(false);
    };

    const onOutcomePressed = (payload: {}, outcome: string) => {
        setShowLoading(true);
        const body: { values: {}, outcome: string, caseDefinitionId?: string, processDefinitionId?: string } = {
            values: payload,
            outcome,
        };
        switch (workItem.type) {
            case 'case':
                body.caseDefinitionId = workItem.id;
                break;
            case 'process':
                body.processDefinitionId = workItem.id;
                break;
        }
        const headers = {
            'Content-Type': 'application/json'
        }

        fetchAuthenticated(keycloak, `${flowableUrl}/platform-api/${workItem.type}-instances`, {
            headers,
            method: 'POST',
            body: JSON.stringify(body)
        })
            .then(response => response.ok ? response.json() : Promise.reject(response))
            .then(() => setSubmitted(true))
            .catch(response => {
                response.json()
                    .then((content) => {
                        console.log('Failed to submit', content);
                        setError(true);
                    });
            })
            .finally(() => {
                setShowLoading(false);
            });
    }

    const customFetch = (url: string, options: {method?: string, headers?: Headers, body: string, mode: RequestMode}, onProgress?: () => void	) => {
        return fetchAuthenticated(keycloak, url, options);
    };

    if (workItem && startForm) {
        return <div>
            <h1>{startForm.dialogTitle || workItem.name}</h1>
            {submitted ?
                <MuiAlert severity="success">Thank you for submitting</MuiAlert> :
                <Form config={startForm}
                      additionalData={{
                          $currentUser: currentUser,
                          endpoints: {
                              action: `${flowableUrl}/action-api`,
                              engage: `${flowableUrl}/engage-api`,
                              form: `${flowableUrl}/form-api`,
                              idm: `${flowableUrl}/idm-api`,
                              report: `${flowableUrl}/platform-api/reports`,
                              cmmn: `${flowableUrl}/cmmn-api`,
                              dataobject: `${flowableUrl}/dataobject-api`,
                              audit: `${flowableUrl}/platform-api`,
                              actuator: `${flowableUrl}/actuator`,
                              baseUrl: `${flowableUrl}/`,
                              content: `${flowableUrl}/content-api`,
                              dmn: `${flowableUrl}/dmn-api`,
                              login: `${flowableUrl}/auth/login`,
                              logout: `${flowableUrl}/auth/logout`,
                              platform: `${flowableUrl}/platform-api`,
                              process: `${flowableUrl}/process-api`,
                              template: `${flowableUrl}/template-api`,
                              inspect: `${flowableUrl}/inspect-api`
                          }
                      }}
                      Components={{}}
                      fetch={customFetch}
                      onOutcomePressed={onOutcomePressed}/>
            }
            {error &&
            <Snackbar open={error} autoHideDuration={6000} onClose={handleCloseError}>
                <MuiAlert onClose={handleCloseError} severity="error">
                    Failed to submit the form, please come back later.
                </MuiAlert>
            </Snackbar>
            }
        </div>;
    }
    return < >Nothing found</>;
}
