import './App.css';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {Work} from "./Work";
import {WorkForm} from "./WorkForm";
import {Messages} from "./Messages";
import {MessagesAml} from "./MessagesAml";
import {Container, makeStyles} from "@material-ui/core";
import {Alert, Backdrop, CircularProgress, Snackbar} from "@mui/material";
import {useFlowableTheme} from "./hooks";
import {useState} from "react";
import * as React from "react";
import {SendMessageForm} from "./SendMessageForm";


function App() {
    const flowableTheme = useFlowableTheme();

    const useStyles = makeStyles({
        background: {
            position: 'fixed',
            left: 0,
            top: 0,
            backgroundColor: flowableTheme?.options?.switcherBase,
            width: '100%',
            height: '100%',
            zIndex: -100
        },
        container: {
            backgroundColor: flowableTheme?.options?.flwFormsBackgroundColor,
            color: flowableTheme?.options?.flwFormsTextColor,
            paddingTop: 15,
            paddingBottom: 15,
            marginTop: 10,
            marginBottom: 10,
            boxShadow: '0 8px 17px 2px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 5px 5px -3px rgb(0 0 0 / 20%)',
            minHeight: 'calc(100vh - 30px)'
        },
        logo: {
            textAlign: 'right'
        }
    });
    const classes = useStyles();
    const [snackbarConfiguration, showSnackbarWithConfiguration] = useState(null);
    const [showLoading, setShowLoading] = useState(false);
    const props = {showSnackbarWithConfiguration, setShowLoading, showLoading};
    let theme = toCssVariables(flowableTheme);

    return <>
        <style type="text/css">
            body {`{${theme}}`}
        </style>
        <div className={classes.background}></div>
        <Backdrop
            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={showLoading}
        >
            <CircularProgress color="inherit"/>
        </Backdrop>
        <Container className={classes.container}>
            {(flowableTheme && flowableTheme.options.logoUrl) && <div className={classes.logo}>
                <img src={flowableTheme.options.logoUrl} width={200} alt="Logo"/>
            </div>}
            <Router>
                <div>
                    <Snackbar open={snackbarConfiguration != null} autoHideDuration={6000}
                              onClose={() => showSnackbarWithConfiguration(null)}>
                        {snackbarConfiguration &&
                            <Alert onClose={() => showSnackbarWithConfiguration(null)}
                                   severity={snackbarConfiguration.severity}>
                                {snackbarConfiguration.message}
                            </Alert>}
                    </Snackbar>
                    <Switch>
                        <Route path="/work/:workDefinitionKey">
                            <WorkForm setShowLoading={setShowLoading} />
                        </Route>
                        <Route path="/messages/fitness-center">
                            <Messages {...props} />
                        </Route>
                        <Route path="/messages/aml-event-handler">
                            <MessagesAml {...props} />
                        </Route>
                        <Route path="/messages/:formKey/:topic">
                            <SendMessageForm {...props} />
                        </Route>
                        <Route path="/">
                            <Work/>
                        </Route>
                    </Switch>
                </div>
            </Router>
        </Container>
    </>;
}

function toCssVariables(flowableTheme) {
    let theme = "";
    for (const option in flowableTheme?.options || {}) {
        const optionName = option.replace(/[A-Z]/g, m => "-" + m.toLowerCase());
        theme += `--${optionName}: ${flowableTheme.options[option]};`;
    }
    return theme;
}

export default App;
